<div>
  <nz-table
    #nzTable nzBordered="true"
    [nzData]="displayInfo.listData" nzSize="small"
    [nzTotal]="totalCount" [nzFrontPagination]="false"
    [nzShowPagination]="totalCount > listData.length"
    [nzPageIndex]="pageIndex"
    [nzLoading]="isLoading"
    [nzLoadingIndicator]="tplLoading"
    (nzPageIndexChange)="onDataListPageChanged($event)"
    >
    <ng-template #tplLoading>
      <div class="nodata"><i nz-icon nzType="loading" nzTheme="outline"></i></div>
    </ng-template>
    <thead>
      <tr>
        <th>Task</th>
        <th nzWidth="220px">Route</th>
        <th>Customer</th>
        <th>Escalation Note</th>
        <th nzWidth="100px">Action</th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let item of displayInfo.listData; let i = index">
        <tr>
          <td>{{ item?.taskName || 'N/A' }}</td>
          <td>
            <div class="flex flex-space-between">
              <div><a [href]="routeToAdminDispatch(item)" target="_blank">{{ item.jobCode }}</a></div>
              <div class="light-text">{{ item.stopDesc }}</div>
            </div>
          </td>
         <td>
          <div *ngIf="item.clientInfo?.name; else noCustomer">
            {{ item.clientInfo.name }}
          </div>
          <ng-template #noCustomer>
            <span class="light-text">N/A</span>
          </ng-template>
         </td>
          <td>
            <div nz-tooltip [nzTooltipTitle]="item.escalationFullNote"
              nzTooltipOverlayClassName="escalation-tooltip"
            >{{ item.escalationNote }}</div>
          </td>
          <td>
            <a [href]="routeToWorkEscalatatedTask(item)" target="_blank">View task</a>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </nz-table>
</div>